import React, { useState, useEffect, useRef } from 'react';
import { Menu, X, ArrowRight, PlayCircle, Clock, Zap } from 'lucide-react';

const LandingPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const featuresRef = useRef(null);
  const howitworksRef = useRef(null);
  const pricingRef = useRef(null);

  useEffect(() => {
    const handleLinkClick = (event) => {
      event.preventDefault();
      const targetId = event.target.getAttribute('href');
      if (targetId === '#features') {
        featuresRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (targetId === '#howitworks') {
        howitworksRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (targetId === '#pricing') {
        pricingRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => link.addEventListener('click', handleLinkClick));

    return () => {
      links.forEach(link => link.removeEventListener('click', handleLinkClick));
    };
  }, []);

  return (
    <div className="min-h-screen bg-black text-white font-geist overflow-x-hidden" role="application">
      <div className="fixed inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black" 
             style={{ 
               backgroundImage: `url('/bg_legacy.png')`, 
               backgroundRepeat: 'no-repeat', 
               backgroundSize: 'cover', 
               backgroundPosition: 'center top',
               opacity: 0.5 
             }} 
        />
      </div>
      
      <div className="relative z-10 w-full">
        <header className="border-b border-gray-800 sticky top-0 bg-black bg-opacity-90" role="banner">
          <div className="w-full max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
            <img src="/dezcribe_logo.png" alt="Dezcribe Logo" className="w-32 h-auto sm:w-48" />
            <nav className="hidden md:block" role="navigation" aria-label="Main Navigation">
              <ul className="flex space-x-6">
                <li><a href="#features" className="hover:text-purple-400" role="link">Features</a></li>
                <li><a href="#howitworks" className="hover:text-purple-400" role="link">How it Works</a></li>
                <li><a href="#pricing" className="hover:text-purple-400" role="link">Pricing</a></li>
              </ul>
            </nav>
            <button 
              className="md:hidden text-white"
              onClick={() => setMenuOpen(!menuOpen)}
              aria-label={menuOpen ? "Close menu" : "Open menu"}
            >
              {menuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </header>
        
        {menuOpen && (
          <div className="md:hidden fixed inset-0 bg-black bg-opacity-90 z-50" role="dialog" aria-modal="true" aria-labelledby="mobile-menu-title">
            <div className="flex flex-col items-center justify-center h-full">
              <button 
                className="absolute top-4 right-4 text-white"
                onClick={() => setMenuOpen(false)}
                aria-label="Close menu"
              >
                <X size={24} />
              </button>
              <h2 id="mobile-menu-title" className="text-2xl font-bold mb-4">Main Navigation</h2>
              <ul className="flex flex-col items-center space-y-6" role="list">
                <li><a href="#features" className="text-2xl hover:text-purple-400" onClick={() => setMenuOpen(false)} role="link">Features</a></li>
                <li><a href="#howitworks" className="text-2xl hover:text-purple-400" onClick={() => setMenuOpen(false)} role="link">How it Works</a></li>
                <li><a href="#pricing" className="text-2xl hover:text-purple-400" onClick={() => setMenuOpen(false)} role="link">Pricing</a></li>
              </ul>
            </div>
          </div>
        )}

        <main className="w-full max-w-7xl mx-auto px-4 py-8 md:py-12" role="main">
          <section className="text-center mb-16">
            <h1 className="text-2xl sm:text-3xl md:text-5xl font-bold mb-4">Easily Make Your Videos Accessible with AI-Powered Audio Descriptions</h1>
            <p className="text-sm sm:text-base md:text-xl text-gray-400 mb-6">Make your content accessible to visually impaired audiences with Dezcribe</p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSekjo2ntik4bTBUGEPmqzfR_scPB7WqH5uGF7OIF2VBD_OudQ/viewform" className="bg-purple-600 text-white px-4 sm:px-6 py-3 sm:py-4 rounded-full text-sm sm:text-lg font-semibold hover:bg-purple-700 transition duration-300" role="link">
              Sign up Now<ArrowRight className="inline ml-2" size={20} />
            </a>
          </section>

          <section id="features" ref={featuresRef} className="mb-16" aria-labelledby="features-title">
            <h2 id="features-title" className="text-xl sm:text-2xl md:text-3xl font-bold mb-6 text-center">Key Features</h2>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6" role="list">
              {[
                { icon: <PlayCircle size={32} />, title: "AI-Powered Descriptions", desc: "Accurate and engaging audio descriptions" },
                { icon: <Clock size={32} />, title: "Quick Turnaround", desc: "Get your videos described in minutes" },
                { icon: <Zap size={32} />, title: "Seamless Integration", desc: "Easy to use with your existing workflow" },
              ].map((feature, index) => (
                <div key={index} className="bg-gray-900 p-4 rounded-lg text-center" role="listitem" aria-label={feature.title}>
                  <div className="flex justify-center mb-4">{feature.icon}</div>
                  <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                  <p className="text-sm text-gray-400">{feature.desc}</p>
                </div>
              ))}
            </div>
          </section>

          <section id="howitworks" ref={howitworksRef} className="mb-16" aria-labelledby="demo-title-1">
            <h2 id="howitworks-title-1" className="text-xl sm:text-2xl md:text-3xl font-bold mb-6 text-center">Why and How We Made It</h2>
            <div className="aspect-video bg-gray-800 rounded-lg flex items-center justify-center">
            <iframe 
              src="https://player.vimeo.com/video/997160935?h=b782c8dc5f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
              frameBorder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowFullScreen
              title="Dezcribe Demo" 
              style={{width: '100%', height: '100%'}} 
            ></iframe>  
            </div>
            
          </section>

          <section id="demo" className="mb-16" aria-labelledby="demo-title-2">
            <h2 id="demo-title-2" className="text-xl sm:text-2xl md:text-3xl font-bold mb-6 text-center">See Dezcribe in Action</h2>
            <div className="aspect-video bg-gray-800 rounded-lg flex items-center justify-center">
            <iframe 
              src="https://player.vimeo.com/video/1006004255?h=162129f9c9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
              frameBorder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowFullScreen 
              title="Dezcribe Demo" 
              style={{width: '100%', height: '100%'}} 
            ></iframe>  
            </div>
            
          </section>

          <section id="pricing" ref={pricingRef} className="mb-20">
            <h2 className="text-2xl sm:text-3xl font-bold mb-8 text-center">Simple Pricing</h2>
            <div className="bg-gray-900 p-8 rounded-lg text-center">
              <h3 className="text-xl sm:text-2xl font-semibold mb-4">Pilot Offer</h3>
              <p className="text-3xl sm:text-4xl font-bold text-purple-400 mb-4">$500</p>
              <p className="text-gray-400 mb-6">for 10 videos</p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSekjo2ntik4bTBUGEPmqzfR_scPB7WqH5uGF7OIF2VBD_OudQ/viewform" className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300" role="link">
                Get Started
              </a>
            </div>
          </section>

          <section id="cta" className="text-center" aria-labelledby="cta-title">
            <h2 id="cta-title" className="text-xl sm:text-2xl md:text-3xl font-bold mb-6">Ready to Make Your Content Accessible?</h2>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSekjo2ntik4bTBUGEPmqzfR_scPB7WqH5uGF7OIF2VBD_OudQ/viewform" className="bg-purple-600 text-white px-4 sm:px-6 py-3 sm:py-4 rounded-full text-sm sm:text-lg font-semibold hover:bg-purple-700 transition duration-300" role="link">
              Try Dezcribe Now
            </a>
          </section>
        </main>

        <footer className="border-t border-gray-800 mt-16 py-8" role="contentinfo">
          <div className="w-full max-w-7xl mx-auto px-4 text-center text-gray-400">
            <p className="text-sm">Built by <a href="https://www.linkedin.com/in/spacedog/" className="hover:text-purple-400" role="link">Patrick</a> and <a href="https://www.linkedin.com/in/aadityav/" className="hover:text-purple-400" role="link">Aaditya</a>, aka The Accessibros</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;